import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'
import * as styles from './news.module.scss'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import IntroText from 'components/IntroText'
import Layout from 'components/Layout'
import NewsLink from 'components/NewsLink'
import Seo from 'components/Seo'
import Social from 'components/Social'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`].nodes[0]
  const posts = data[`posts_${locale}`].nodes
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]
  const introText = renderRichText(page.introText)
  // const seoDescription = `${posts[0].postDate}: ${posts[0].title} - ${tr(
  //   'READ_MORE',
  //   locale
  // )}`

  return (
    <Layout>
      <div>
        <Seo
          pageTitle={page.title}
          // description={seoDescription}
        />
        <Banner image={page.banner} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <div className={styles.introWrap}>
              <IntroText content={introText} addClass='news' />
              <div className={styles.socialWrap}>
                <Social />
              </div>
            </div>
            <div className={styles.posts}>
              {posts.map((post, i) => (
                <NewsLink key={i} post={post} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: allContentfulNewsPage(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
      }
    }
    posts_en: allContentfulNewsPost(
      sort: { fields: postDate, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        slug
        title
        postDate(formatString: "YYYY-M-DD")
      }
    }
    page_zh: allContentfulNewsPage(filter: { node_locale: { eq: "zh-CN" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
      }
    }
    posts_zh: allContentfulNewsPost(
      sort: { fields: postDate, order: DESC }
      filter: { node_locale: { eq: "zh-CN" } }
    ) {
      nodes {
        slug
        title
        postDate(formatString: "YYYY-M-DD")
      }
    }
  }
`
